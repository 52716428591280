@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,600;9..40,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;300;400&display=swap');

body {
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  /* background-image: url("./assets/images/bg.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-out;
}

.slide-exit {
  transform: translateX(0);
}

.slide-exit-active {
  transform: translateX(100%);
  transition: transform 300ms ease-out;
}

.App {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.paddle {
  position: absolute;
  width: 10px;
  height: 100px;
}

.ball {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.score {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 18px;
}

.game-over {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 30px;
  z-index: 10; /* Add a higher z-index value */
}


@keyframes cheersAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.cheer-emoji {
  animation: cheersAnimation 1s infinite;
}
